<template>
  <div class="table">
    <el-button @click="checkAll" class="button-primary">全选</el-button>
    <el-button @click="getChecked" class="button-primary">获取选择</el-button>
    <hr>
    <img src="@/assets/images/svg/attachment.svg" style="color: red;" alt="">
    <table v-if="tableData.length">
      <tbody>
        <tr>
          <td style="width: 35px;">
            <input @change="checkAll" type="checkbox">
          </td>
          <td style="width: 200px;">子目号</td>
          <td>名称</td>
          <td style="width: 500px;">数量</td>
          <td style="width: 300px;">单位</td>
          <td style="width: 200px;">父节点</td>
          <td style="width: 200px;">顺序</td>
        </tr>
      </tbody>
    </table>
    <div ref="tbody" class="tbody">
      <table v-if="tableData.length">
        <tbody>
          <tr
            v-for="row of tableData"
            :key="row.engineeringListGuid"
            >
            <td style="width: 35px;">
              <input type="checkbox" v-model="row.checked">
            </td>
            <td style="width: 200px;">{{ row.engineeringListCode }}</td>
            <td>{{ row.engineeringListName }}</td>
            <td style="width: 500px;">{{ row.num }}</td>
            <td style="width: 300px;">{{ row.unit }}</td>
            <td style="width: 200px;">{{ row.parentId }}</td>
            <td style="width: 200px;">{{ row.sortId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import auth from '@/common/auth'
import engineeringListService from '@/services/engineeringListService'
import BScroll from 'better-scroll'

export default {
  name: 'Table',
  data () {
    return {
      tableData: []
    }
  },
  methods: {
    checkAll (event) {
      const checked = event.target.checked
      this.tableData.forEach(item => {
        item.checked = checked
      })
    },
    getChecked () {
      const checkedList = this.tableData
        .filter(item => item.checked)
        .map(item => item.engineeringListCode)
        .join(',')
      console.log(checkedList)
    },
    getData () {
      engineeringListService.list({}, { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.tableData = res.data.data.map(item => {
              item.checked = false
              return item
            })
            this.$nextTick(() => {
              this.initScroll()
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    initScroll () {
      this.yScroll = new BScroll(this.$refs.tbody, {
        mouseWheel: true,
        scrollX: false,
        scrollY: true,
        preventDefaultException: {
          tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|TABLE|TD)$/
        }
      })

      this.xScroll = new BScroll(this.$refs.table, {
        mouseWheel: false,
        scrollX: true,
        scrollY: false,
        eventPassthrough: 'vertical',
        preventDefaultException: {
          tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|TABLE|TD)$/
        }
      })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
  th, td {
    height: 28px;
    line-height: 28px;
    font-size: 15px;
    padding: 2px 10px;
    height: 30px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    input {
      width: 15px;
    }
  }
}
.tbody {
  margin-top: -1px;
  height: 500px;
  overflow: hidden;
}
</style>
